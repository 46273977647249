import React, { useState } from 'react'
import ReactLoading from 'react-loading';
import {Link} from 'react-router-dom'

export default function Header({data, type}) {
  const [menu, setMenu] = useState(null)
  
    function handleLogout(){
        localStorage.clear();
        window.location.href = '/'
    }

  return (
    <>
        <header className='flex justify-between w-full sm:w-[60%] mt-2'>
            {type == "profile" && (
                <div style={{cursor: 'pointer'}} onClick={()=> setMenu(!menu)} className='font-bold'>
                    
                    <i class="fa-solid fa-user"></i> Perfil
                </div>         
            )}
          
            {type == "back" && (
                <a href={'/dashboard'}><button className='font-bold'><i class="fa-solid fa-arrow-left "></i> Para volver</button></a>          
            )}

            <div className='flex items-center'>
                <div className='flex items-center'><i class="fa-solid fa-wallet"></i> <span className='font-bold'>Portafolio:</span> 
                
                {!data ? 
                    <div className='flex items-center ml-2'>
                    <ReactLoading type={'spinningBubbles'} color={'#000'} height={22} width={22} /> 
                    </div> 
                    :
                    <div className='ml-2'>
                        $ {data?.valor}
                    </div>
                }

                </div>

                {menu && <div onClick={()=> handleLogout()} className='ml-4 hover:text-zinc-800 cursor-pointer'>Logout<i class="fa-solid fa-arrow-right ml-1"></i> </div>}

            </div>

        </header>
        <div className='border-b-2 dark:border-zinc-800 border-zinc-100 w-full mt-4'></div>
      
    </>
  )
}
