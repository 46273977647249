import { Switch, Route, Routes, BrowserRouter as Router} from 'react-router-dom';
// layouts
//
import Dashboard from '../pages/dashboard'
import Redirect from '../pages/redirecionando'
import Video from '../pages/video'
import App from '../pages/index'
import Saque from '../pages/Saque';


// ----------------------------------------------------------------------
import { PrivateRoute } from "./auth.routes";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
          <Route  path="/" element={<App/>}/>
          <Route  path="/saque" element={<Saque/>}/>
          <Route  path="/video" element={<Video/>}/>
          <Route  path="/redirect" element={<Redirect/>}/>
          
          <Route path="/dashboard" element={<PrivateRoute />}>
            <Route path="/dashboard"  element={<Dashboard/>} />
          </Route>        
      </Routes>
    </Router>
  );
}

