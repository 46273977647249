import React,{ useEffect, useState, useContext } from 'react';
import logo from '../logo.png';
import {Link} from 'react-router-dom'
import '../index.css'
import { AuthContext } from '../context/auth';
import { UpdateSaldo, getUser , verifyFrequecia, systemRebot } from '../services/database'
import { useQuery as useQuery } from 'react-query'
import Header from '../components/Header';
import ReactLoading from 'react-loading';

function Videos() {
    const [calc, setCalc] = useState(null)
    const [validateTime, setValite] = useState(false)
    const [update, setUpdate] = useState(false)
    const { user } = useContext(AuthContext);

    const [prova, setProva] = useState(null)
    const [tempo, setTempo] = useState(null)
    const [valor, setValor] = useState(null)
    const [myuser, setUser] = useState(null)

    const [text, setText] = useState('Por favor espera estamos cargando play')
    
    const [saldo, setSaldo] = useState(true)
    const [tst, setTst] = useState(null)

    const prova_existencia = 600000
    const tempo_saldo = 60000

    useEffect(() => {
        userInfo()
        frequencia()
    } ,[])

    useEffect(() => {
        if(saldo) {
            setTimeout(() => {
                setText("Sólo un momento")

                rebotSeve()
            },5000)
        }
    } ,[])
    
    useEffect(() => {
        const interval = setInterval(() => {
            userInfo()
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    async function rebotSeve(){
        let res = await systemRebot()

        setTimeout(() => {
            setSaldo(false)
        }, 5000)

        return res
    }

    async function frequencia() {
        let res =  await verifyFrequecia()
        
        console.log("frequencia", res.data[0])

        setTempo(parseInt(res.data?.[0].tempo))
        setProva(parseInt(res.data?.[0].intervalo))
        setValor(parseInt(res.data?.[0].valor))
        return res
    }

    async function handleUpdateSaldo() {
        let data = {userid: user?._id, valor: valor, tempo: tempo}
        let res = await UpdateSaldo(data)
        console.log("atualiza saldo", res)
    }


    async function handleUpdateSaldoMobile() {
        let data = {userid: user?._id, valor: valor, tempo: tempo}
        let res = await UpdateSaldo(data)
        console.log("atualiza saldo", res)

        window.location.href='/'
    }


    async function userInfo() {
        
        const storageUser = localStorage.getItem("@login-id");

        if (storageUser) {
          setUser(JSON.parse(storageUser));
          console.log("userss", storageUser)
        }
        
        let res = await getUser(JSON.parse(storageUser)._id)
        console.log("user", res)
        setValite(true)
        setCalc(res.data[0].valor)
        return res
    }

    const handleEvent = () => {

        handleUpdateSaldo()
    }

    const handleEventMobile = () => {

        handleUpdateSaldoMobile()

       
    }
    

    const info = {
        valor: calc && calc,
    }

    return (
        <div className="App p-4 dark:bg-zinc-900 dark:text-white flex items-center flex-col min-h-full md:min-h-screen">
        <Header data={info} type="back" />
        <div className='flex items-center flex-col w-full sm:w-[60%] mt-12 text-center'>
            <img className='w-[200px] mt-8' src={logo} alt="logo" />
            <h1 className='text-3xl font-bold mt-8'> ¡Falta poco! </h1>
            <h2 className='mt-8 dark:text-zinc-400'>Para comenzar a ganar, haga clic en el botón de abajo para ir a TikTok y ver sus videos 🤑.</h2>
            <div className='w-full sm:w-[60%]'>
                {!saldo && (
                    <>
                        <div onClick={()=> handleEvent()} className='mt-8'>
                            <a className='invisible sm:visible'  href="/redirect" target="_blank">
                                <button className='bg-black border-b-4 border-zinc-800 dark:bg-[#fff] dark:text-black p-3 text-white font-bold w-full rounded-md'>tiktok abierto</button>
                            </a>
                        </div>

                        <div onClick={()=> handleEventMobile()} >
                            <a className='visible sm:invisible' href="https://tiktok.com" target="_blank">
                                <button className='bg-black border-b-4 border-zinc-800 dark:bg-[#fff] dark:text-black p-3 text-white font-bold w-full rounded-md'>tiktok abierto</button>
                            </a>
                        </div>
                    </>
                )}
                {saldo && 
                    <button className='bg-zinc-400 border-b-4 flex justify-center items-center border-zinc-500 dark:bg-[#fff] mt-8 mb-4 dark:text-black p-3 text-white font-bold w-full rounded-md'>
                        {text}  
                        <ReactLoading className='ml-4' type={'spinningBubbles'} color={'#fff'} height={20} width={20} />
                    </button>
                }
                <br/>
                <span className='text-red-500'>¡Debes dejar esta pestaña y el navegador abiertos!</span>
            </div>
        </div>
        </div>
    );
}

export default Videos;