import React,{ useEffect } from 'react'
import ReactLoading from 'react-loading';
import '../index.css'

export default function Redirecionado() {

  useEffect(()=> {
      setTimeout(() => {
          window.location.href="https://tiktok.com"
      }, 2000)
  },[])

  return (
    <div className='flex items-center text-center dark:bg-zinc-900 flex-col min-h-full md:min-h-screen'>
      <h1 className='text-4xl dark:text-white mt-24'>Redirigir</h1>

      <h1 className='text-2xl mt-8 dark:text-zinc-300 text-zinc-600'>Esperar...</h1>
      
      <ReactLoading className='mt-8' type={'spinningBubbles'} color={'#333'} height={100} width={100} />

      <span className='text-red-500 mt-12'>¡Debes dejar esta pestaña y el navegador abiertos!</span>
    </div>
  )
}
