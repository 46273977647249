import {React, useContext} from 'react'
import logo from '../logo.png';
import {Link} from 'react-router-dom'
import { Line } from 'rc-progress';
import { motion } from "framer-motion"
import { useState, useEffect } from 'react';
import '../index.css'
import { AuthContext } from '../context/auth';
import { getUser, verifySaques, systemRebot, getUserMeta } from '../services/database'
import { useQuery as useQuery } from 'react-query'
import ReactLoading from 'react-loading';
import Header from '../components/Header';

function App() {
  const [saldo, setSaldo] = useState(localStorage.getItem("saldo") ? localStorage.getItem("saldo")  : 20)
  const [menu, setMenu] = useState(false)
  const [value, setValue] = useState(null)
  const { signed, singOut, user } = useContext(AuthContext);
  const [saques, setSaques] = useState(null)
  const [saqueModal, setSaqueModal] = useState(false)

  const [rebot, setRebot] = useState(true)
  
  const [meta, setMeta] = useState(null)

  const { data, isError, isLoading } = useQuery(["users", value], async () => userInfo())
  const  resultSaque = useQuery(["saques", saques],  async () => userSaques())

  useEffect(() => {
    userMeta()
    setTimeout(() => {
      rebotSeve()
    }, 10000)
  },[])

  async function userInfo() {
      let res = await getUser(user._id)
      setValue(res.data[0].valor)
      return res
  }

  async function rebotSeve(){
    let res = await  systemRebot()
    setRebot(false)

    return res
}

  async function userMeta () {
    let res = await getUserMeta()
    setMeta(res.data[0].meta)
  }

  async function userSaques() {

    
    let res = await verifySaques(user._id)

    console.log("saques", res.data)

    setSaques(res.data)

    return res
  }


  function Saques(props) {
    console.log("data", props)
    return (
      <div className='w-full sm:w-[70%] flex items-center flex-col'>
        <div className='w-full sm:w-[70%] flex items-center flex-col mt-8'>
          <div className='flex justify-between w-full items-center'>
            <h1 className='font-bold text-zinc-400'>Mi saqueo</h1>
            <button onClick={()=> setSaqueModal(false)} className='w-12 h-12 items-center flex justify-center p-2 border-2 border-zinc-100 rounded-full text-zinc-400 font-bold hover:bg-gray-100'>X</button>
          </div>

          <div className='w-full mt-8'>
            <div className='flex w-full text-xs sm:text-sm bg-zinc-100 p-2 font-bold justify-between'>
                <div className='w-[10%]'>id</div>
                <div className='w-[20%]'>correo electrónico</div>
                <div className='w-[20%] invisible sm:visible'>cantidad de retiro</div>
                <div className='w-[20%]'>fecha</div>
                <div className='w-[20%]'>estado</div>
              </div>
          </div>
          <div className='w-full mt-8'>
            <hr />
            {resultSaque?.data.data.map((res) => (
              <>
              <div className='flex w-full p-2 text-xs sm:text-sm justify-between'>
                <div className='w-[10%]'>{res.id}</div>
                <div className='w-[20%]'>{res.email_paypal}</div>
                <div className='w-[20%] invisible sm:visible'>${res.valor_saque}</div>
                <div className='w-[20%]'>{res.data_saque}</div>
                <div className='flex w-[20%] items-center'>
                  {res.status}
                </div>
              </div>
              <hr />
              </>
            ))}
          </div>
        </div>
      </div>
    )
  }

  console.log("react-qeury", !isLoading && data?.data[0].valor)

  const info = {
    valor: data?.data[0].valor,
  }

  return (
    <div className="App p-4 flex items-center flex-col bg-w dark:bg-zinc-900 dark:text-white min-h-full md:min-h-screen">
      <Header data={data && info} type="profile" />
      {saqueModal ? 
        <Saques data={saques && saques} /> 
        : 
        <>
        <div className='flex justify-between w-full sm:w-[60%] h-[30px]'>
          {menu && <div className='flex justify-between w-[60%]'><button onClick={()=> singOut()} className='mt-1 text-sm bg-zinc-900 dark:bg-white dark:text-black p-1 text-white'><i class="fa-solid fa-right-from-bracket"></i> cerrar sesión </button></div>}
        </div>
  
        <div className='flex w-full sm:w-[70%] items-center flex-col mt-12'>
          <h1 className='text-3xl'> Bienvenido <span className='font-bold'>{user.nome}</span>  </h1>
          <img className='w-[200px] mt-12' src={logo} alt="logo" />
  
        <div className='w-full sm:w-[70%] mt-4'>
          <br />
          <div className='flex justify-between pb-2'>
            <div>
              {isLoading ? 
                <div className='flex items-center'>
                  <ReactLoading type={'spinningBubbles'} color={'#000'} height={22} width={22} /> 
                </div> 
                :
                <div>
                $ {data?.data[0].valor}
                </div>
              }
            </div>
            {!isLoading && data?.data[0].valor >= meta ? 
            <>
              <h1 className='font-bold text-[#55efc4]'>Meta botín: ${meta && meta}</h1>
            </>
            :
            <>
              <h1 className='font-bold text-zinc-400'>Meta botín: ${meta && meta} </h1>
            </>
          }
          </div>
          {!isLoading && data?.data[0].valor >= meta ? 
          <>
            <Line percent={100} strokeWidth={2} trailWidth={2} strokeColor="#55efc4" />
          </>
          :
          <>
            <Line percent={!isLoading && data?.data[0].valor / ( meta?.length > 3 && meta?.substr(0,2))} strokeWidth={2} trailWidth={2} strokeColor="#000" />
          </>  
        }
  
          {!isLoading && data?.data[0].valor >= meta ? 
          <>
            <div className='mt-4'>
              <Link to={'/saque'}>
                <button className='bg-[#55efc4] p-3 text-black font-bold w-full rounded-md'>Quiero retirar mi dinero</button>
              </Link>
  
  
            </div>
          </>
          : 
          <>
            <div className='mt-4 flex'>
              <button className='bg-zinc-200 p-3 text-zinc-400  p-3 text-black font-bold w-full rounded-md'>Quiero retirar mi dinero</button>
              
              {!resultSaque.data?.data && (
                <button className='w-[200px] bg-zinc-200 p-3 text-zinc-400  p-3 text-black font-bold ml-2 rounded-md'>
                  retiros
                </button>
              )}

              {resultSaque.data?.data.length > 0 && (
                <button onClick={()=> setSaqueModal(true)} className='border-2 w-[200px] text-center justify-center border-zinc-200 flex items-center text-zinc-900 hover:bg-gray-100 bg-zinc-100 p-2 ml-2 rounded-md font-bold'>
                  retiros
                  <div className='w-5 ml-2 text-white text-xs items-center justify-center flex h-5 bg-red-400 rounded-full'>
                     {resultSaque.data?.data.length}
                  </div>
                </button>
              )}


  
          </div>
          </>
          }
          
          <div className='relative'>
          <div className='mt-4 h-[220px] flex items-center justify-center' style={{backgroundImage: 'url(https://mundoconectado.com.br/uploads/chamadas/funcionarios-do-tiktok-podem-decidir-manualmente-quais-videos-vao-viralizar.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: "cover", filter: 'blur(3px)' }}>
          </div>
  
          <div  style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} className='absolute z-2'>
            <a href={'/video'}>
                <motion.div
                
                  className='z-2'
                  whileTap={{ scale: 0.8 }}
                  animate={{
                    scale: [0.8, 1.2, 1],
                    
                  }}
                >
                  <button style={{border: '4px solid #fff', color: '#fff'}} className='w-24 h-24 bg-[#f53b57] rounded-full'><i class="fa-solid fa-play text-3xl"></i></button>
                </motion.div>
            </a>
          </div>
          </div>
          <h1 className='text-center mt-4 text-zinc-400'>haga clic en el botón de arriba para ver</h1>
        </div>
  
        </div>
        </>
      }

    </div>
  );
}


export default App;
