import {api} from './api'
import axios from 'axios'

export async function login(data) {
    const res = await api.post('/login', data)
    return res
}

export async function cadastro(data) {
    const res = await api.post('/cadastro', data)
    return res
}

export async function saque(data) {
        const res = await api.post('/saque', data)
    return res
}

const controller = new AbortController();



export async function UpdateSaldo(data) {
    
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const res = await api.put('/user/saldo', data, { cancelToken: source.token })

    if(res) {
        return res
    }

    source.cancel('Operation canceled by the user.');
    
}

export function cancel(){
    controller.abort()
}

export async function RestartGame(data) {
    const res = await api.put('/user/restart', data)
return res
}

export async function getUser(id) {
    const res = await api.get(`/user/${id}`)
    return res
}

export async function verifyEmail(email) {
    const res = await api.get(`/verify/${email}`)
    return res
}

export async function UpdateUser(data) {
    const res = await api.put('/update/user', data)
return res
}

export async function verifySaques(id) {
    const res = await api.get(`/saque/${id}`)
    return res
}

export async function verifyScreen(screen) {
    const res = await api.get(`/screen/${screen}`)
    return res
}

export async function verifyFrequecia() {
    const res = await api.get(`/frequencia`)
    return res
}


export async function systemRebot() {
    const res = await api.get(`/system/reboot`)
    return res
}


export async function getUserMeta() {
    const res = await api.get(`/meta`)
    return res
}

export async function verifyScreenNotification() {
    const res = await api.get(`/notification`)
    return res
}

export async function getPublicKey() {
    const res = await api.get(`/push/public_key`)
    return res
}

export async function pushRegister(data) {
    const res = await api.post('/push/register', data)
    return res
}

export async function pushNotify(data) {
    console.log("push", data)
    const res = await api.post('/push/send', data)
    return res
}
