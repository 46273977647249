import React from 'react'
import AppRoutes from './app.routes'
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../context/auth';

export default function App() {

  return(
    <AuthProvider>
        <AppRoutes />
    </AuthProvider>
  )

}