import React,{useContext, useState, useEffect} from 'react'
import logo from '../favicon.png';
import image2 from '../logo.png';
import { Link } from 'react-router-dom';
import '../index.css'
import { useTheme } from '../hooks/useTheme';
import { AuthContext } from '../context/auth';
import { Navigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import {motion} from 'framer-motion'
import {verifyEmail, UpdateUser, verifyScreen} from '../services/database'

export default function Index() {
  const {theme, setTheme} = useTheme()
  const { Login, signed, Cadastro } = useContext(AuthContext);
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [nome, setNome] = useState('')
  const [id, setId] = useState('')

  const [load, seLoad] = useState(null)
  const [loadLogin, setLoadLogin] = useState(null)

  
  const [pendente, setPendente] = useState(false)
  const [login, setLogin] = useState(false)

  const [enableCad, setEnableCad] = useState(false)
  const [enableCadModal, setEnableCadModal] = useState(false)


  useEffect(() => {
    getScreen()
  },[])

  async function getScreen(){
    let data = await verifyScreen("cadastro")
    data.data[0].enable == 1 && setEnableCad(true)
  }

  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      return (true)
    }
      return (false)
  }

  async function authentication() {
    setLoadLogin(true)
 
    if (ValidateEmail(email)) {
      let resp = await verifyEmail(email)

      if(resp.data[0]) {
        setId(resp.data[0].id)
        setPendente(true)
        setLoadLogin(false)
  
      } else {
        console.log("ativar senha")
        setLogin(true)
        setLoadLogin(false)
      }
    } else {
      setLoadLogin(false)

    }


  }

  async function loginDB(){
    seLoad(true)
    const data = {email: email, senha: pass}
    try {
      let res = await Login(data)

      console.log(res)
  
      if(res.data.email) {
        window.location.href = '/dashboard'
      } else {
        seLoad(false)
      }
    } catch {
      seLoad(false)
    }
  }

  async function updateDB(){
    seLoad(true)
    const data = {password: pass, name: nome, id: id}
    try {
      let res1 = await UpdateUser(data)

      const login = {email: email, senha: pass}

      let res = await Login(login)

      console.log(res)
  
      if(res.data.email) {
        window.location.href = '/dashboard'
      } else {
        seLoad(false)
      }
    } catch {
      seLoad(false)
    }
  }


  async function handleSubmitCadastro() {
    seLoad(true)
    const data = {nome: nome, email: email, password: pass}
    try {
      if (ValidateEmail(email)) { 
        let res = await Cadastro(data)

        console.log("cadastroo", res)
    
        if(res.data.email) {
          window.location.href = '/dashboard'
        } else {
          seLoad(false)
        }
      } else {
        seLoad(false)
      }
    } catch {
      seLoad(false)
    }
  }
  
  if (!signed) {
    return (
      <div className='App p-4 flex items-center flex-col bg-w justify-center dark:bg-zinc-900'>
        <div className='top-0  fixed w-full bg-white dark:bg-zinc-900 z-30 flex-col items-center flex'>
        <header className='flex pl-2 pr-2 sm:pl-0 sm:pr-0 items-center justify-between w-full sm:w-[60%] mt-4'>
          <div onClick={()=> window.location.reload()} className='font-bold cursor-pointer flex items-center dark:text-white'> <img className='w-[22px] mr-2' src={logo} alt="logo" /> Tiktok monetize </div>
          {theme === "ligth" ? (
            <div onClick={()=> setTheme("dark")} className='w-[22px] h-[22px] flex items-center justify-center' style={{border: '1px solid #000', padding: 2, borderRadius: 11}}> <i class="fa-solid fa-circle-half-stroke"></i></div>
          ) : (
            <div onClick={()=> setTheme("ligth")} className='w-[22px] h-[22px] flex items-center justify-center' style={{border: '1px solid #000', padding: 2, borderRadius: 11}}> <i class="fa-solid dark:text-white fa-circle-half-stroke"></i></div>
          )}
        </header>
            <div className='border-b-2 dark:border-zinc-800 border-zinc-100 w-full mt-4'></div>
        </div>

      <div style={{height: '100vh'}} className='flex sm:flex-row flex-col dark:text-white rounded-md w-full sm:w-[60%] justify-between items-center'>
          {pendente ? 
            <>
              <div className='flex flex-col w-full sm:w-[50%] p-4 '>
                <div className='flex flex-col items-center'>            
                  <img className='w-[80px] mt-8' src={image2} alt="logo" />
                  <h1 className='text-[black] dark:text-white text-2xl mt-4 ml-2 font-bold'>Ingrese a su cuenta</h1>
                </div>

                <input value={email} className='p-4 mt-8 w-full bg-gray-100 dark:bg-zinc-800 rounded-md' type="email" name="" placeholder='Escriba su correo electrónico' id="" />
                <input value={nome} onChange={(e) => setNome(e.target.value)} className='p-4 mt-4 w-full bg-gray-100 dark:bg-zinc-800 rounded-md' type="nome" name="" placeholder='Escriba su nombre' id="" />
                <input value={pass} onChange={(e) => setPass(e.target.value)} className='p-4 mt-4 w-full bg-gray-100 dark:bg-zinc-800'  type="password" placeholder='Escribe tu contraseña'  name="" id="" />
                <button onClick={()=> updateDB()} className='p-4 bg-black dark:bg-zinc-800 dark:text-white text-white mt-4 text-center flex justify-center w-full font-bold rounded-md'>
                    {load ? <ReactLoading type={'spinningBubbles'} color={'#fff'} height={20} width={20} /> : <span>Hacer logi</span>}
                </button>
              </div>
            </>
          :
            <>
            {login ? 
              <>
              <div className='flex flex-col w-full sm:w-[50%] p-4 '>
                <div className='flex flex-col items-center'>            
                  <img className='w-[80px] mt-8' src={image2} alt="logo" />
                  <h1 className='text-[black] dark:text-white text-2xl mt-4 ml-2 font-bold'>Ingrese a su cuenta</h1>
                </div>

                <div className='w-full h-[20px] mt-4 flex items-center justify-center'>
                  {load == false && <h1 className='text-red-400'>Correo electrónico o contraseña no válidos</h1>}
                </div>

                <input value={email} onChange={(e) => setEmail(e.target.value)} className='p-4 mt-8 w-full bg-gray-100 dark:bg-zinc-800 rounded-md' type="email" name="" placeholder='Escriba su correo electrónico' id="" />
                <input value={pass} onChange={(e) => setPass(e.target.value)} className='p-4 mt-4 w-full bg-gray-100 dark:bg-zinc-800'  type="password" placeholder='Escribe tu contraseña'  name="" id="" />
                <button onClick={()=> loginDB()} className='p-4 bg-black dark:bg-zinc-800 dark:text-white text-white mt-4 text-center flex justify-center w-full font-bold rounded-md'>
                    {load ? <ReactLoading type={'spinningBubbles'} color={'#fff'} height={20} width={20} /> : <span>Hacer logi</span>}
                </button>
              </div>
              </>
            :
              <>
              <div className='flex flex-col w-full sm:w-[50%] p-4 '>
                <div className='flex flex-col items-center'>            
                  <br />
                  <img className='w-[80px] mt-8' src={image2} alt="logo" />
                  <h1 className='text-[black] dark:text-white text-2xl mt-4 ml-2 font-bold'>Ingrese a su cuenta</h1>
                </div>

                {enableCadModal ?
                <motion.div className='z-2' initial={{ y: -20 }} animate={{ y: 0 }} >
                  {load == false && <h1 className='text-red-400 text-center'>Correo electrónico no válidos</h1>}
                  <input value={nome} onChange={(e) => setNome(e.target.value)} className='p-4 mt-4 w-full bg-gray-100 dark:bg-zinc-800 rounded-md' type="nome" name="" placeholder='Escriba su nombre' id="" />
                  <input value={email} onChange={(e) => setEmail(e.target.value)}  className='p-4 mt-4 w-full bg-gray-100 dark:bg-zinc-800 rounded-md' type="email" name="" placeholder='Escriba su correo electrónico' id="" />
                  <input value={pass} onChange={(e) => setPass(e.target.value)} className='p-4 mt-4 w-full bg-gray-100 dark:bg-zinc-800'  type="password" placeholder='Escribe tu contraseña'  name="" id="" />
                  <button onClick={()=> handleSubmitCadastro()} className='p-4 bg-black dark:bg-zinc-800 dark:text-white text-white mt-4 text-center flex justify-center w-full font-bold rounded-md'>
                      {load ? <ReactLoading type={'spinningBubbles'} color={'#fff'} height={20} width={20} /> : <span>inscribirse</span>}
                  </button>
                </motion.div> 
                :
                <>
                  <div className='w-full h-[20px] mt-4 flex items-center justify-center'>
                    {load == false && <h1 className='text-red-400'>Correo electrónico o contraseña no válidos</h1>}
                  </div>
                  <motion.div className='z-2' initial={{ y: -20 }} animate={{ y: 0 }}>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} className='p-4 mt-8 w-full bg-gray-100 dark:bg-zinc-800 rounded-md' type="email" name="" placeholder='Escriba su correo electrónico' id="" />
                    
                    <button onClick={()=> authentication()} className='p-4 bg-black dark:bg-zinc-800 dark:text-white text-white mt-4 text-center flex justify-center w-full font-bold rounded-md'>
                        {loadLogin ? <ReactLoading type={'spinningBubbles'} color={'#fff'} height={20} width={20} /> : <span>Hacer logi</span>}
                    </button>
                  </motion.div>
                  {enableCad && (
                  <motion.div className='z-2' initial={{ y: -30 }} animate={{ y: 0 }}>
                    <h1 className='text-center mt-4'>¿No tenías una cuenta?</h1>
                    <button onClick={()=> setEnableCadModal(true)} className='p-4 mt-2 bg-zinc-700 dark:bg-zinc-800 dark:text-white text-white mt-1 text-center flex justify-center w-full font-bold rounded-md'>
                        <span>inscribirse</span>
                    </button>
                  </motion.div>
                  )}
                </>
                }
              </div>
              </>
            }

            </>
          }

          

          <div className='flex invisible sm:visible  w-full sm:w-[50%]  items-center justify-center flex-col bg-zinc-100 h-screen p-2 dark:bg-zinc-800'>
            <h1 className='text-2xl font-bold text-center'>escanear el código qr para descargar la aplicación</h1>
            <img className='mt-8' style={{width: 150}} src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz" alt="" srcset="" />
          </div>
      </div>
      </div>
    )
  } else {
    return <Navigate to="/dashboard" />;

  }
}
